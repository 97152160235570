










































































import Vue from 'vue'
import { Project, Requisition, ApiResponse } from '@/interfaces'
import { mapState } from 'vuex'

interface Badge {
  color: string
  value: string
}

interface DropdownAction {
  action: string
  requisition?: Requisition
  project?: Project
}

interface NatureObject {
  label: string
  value: string
}

export default Vue.extend({
  name: 'RequisitionsTable',
  props: {
    projects: {
      type: Array,
      required: false,
      default: () => [] as Project[],
    },
    page: {
      type: String,
      required: false,
      default: null,
    },
    view: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      natures: [] as NatureObject[],
      customColors: [
        { color: '#48C989', percentage: 25 },
        { color: '#F7AD57', percentage: 50 },
        { color: '#E8584F', percentage: 75 },
        { color: '#263238', percentage: 100 },
      ],
    }
  },
  computed: {
    ...mapState(['user', 'scrollPosition']),
    statusFilters() {
      return [
        { text: 'Ouvert', value: 'ouvert' },
        { text: 'Prospect', value: 'prospect' },
        { text: 'Rendez-vous pris', value: 'rendez-vous_pris' },
        { text: 'En attente', value: 'en_attente' },
        { text: 'En cours', value: 'en_cours' },
        { text: 'Inventaire', value: 'inventaire' },
        { text: 'Préparation de vente', value: 'preparation_vente' },
        { text: 'Vente en cours', value: 'vente_en_cours' },
        { text: 'Difficulté', value: 'difficulte' },
        { text: 'Deuxième relance', value: '2eme_relance' },
        { text: 'Mise en demeure', value: 'mise_en_demeure' },
        { text: 'Reglé', value: 'regle' },
        { text: 'Clôturé', value: 'clos' },
        { text: 'Annulé', value: 'annule' },
      ]
    },
  },
  methods: {
    formatDate(str: string, hours = false): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        if (hours) {
          const day = new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          }).format(date)
          const hour = new Intl.DateTimeFormat('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(date)

          return `${day} à ${hour}`
        } else {
          return new Intl.DateTimeFormat('fr-FR').format(date)
        }
      } else {
        return '-'
      }
    },
    sortChange() {
      this.$emit('sortChange')
    },
    handleInventoryCommand(command: DropdownAction) {
      // Edit inventory
      if (command.action == 'edit' && command.project) {
        this.$emit('editInventory', command.project.id)
      }

      // Delete inventory
      if (command.action == 'delete' && command.project) {
        this.deleteProject(command.project)
      }

      // Delete inventory permanently
      if (command.action == 'delete_permanently' && command.project) {
        this.deleteProjectPermanently(command.project)
      }

      // Restore inventory
      if (command.action == 'restore' && command.project) {
        this.restoreProject(command.project)
      }
    },
    deleteProject(project: Project) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cet inventaire ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/project/${project.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$emit('getProjects')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    deleteProjectPermanently(project: Project) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer définitivement cet inventaire ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/project/${project.id}/permanent/inventory`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$emit('getProjects')
          })
          .catch((error) => {
            const apiResponse = error.response.data as ApiResponse
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
      })
    },
    restoreProject(project: Project) {
      this.$api
        .put(`/project/${project.id}/restore`)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Opération réalisée avec succès !',
          })

          this.$emit('getProjects')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
          })
        })
    },
    getNature(nature: string): string {
      const natureObject = this.user.client.config.natures.find((item: NatureObject) => item.value == nature)

      return typeof natureObject !== 'undefined' ? natureObject.label : '-'
    },
    goToInventory(row: Project, column: Record<string, string>, cell: HTMLTableCellElement) {
      if (!cell.classList.contains('actions')) {
        this.$router.push({
          name: 'inventory_edit',
          params: {
            projectId: row.id?.toString() ?? '',
            inventoryId: row.inventory?.id?.toString() ?? '',
          },
        })
      }
    },
    getPercentage(num: number): number {
      if (num > 0) {
        const percentage = num * 100

        return percentage >= 100 ? 100 : percentage
      } else {
        return 0
      }
    },
    getStructureType(project: Project) {
      return project.structure?.type ?? null
    },
    getStructureName(project: Project) {
      return project.structure?.name ?? '-'
    },
    filterHandler(value: string, row: Record<string, string>, column: Record<string, string>) {
      const property = column['property']

      return row[property] === value
    },
    getStatusBadge(project: Project): Badge {
      switch (project.status) {
        case 'ouvert':
          return {
            color: '#EFE6FD',
            value: 'Ouvert',
          }
        case 'prospect':
          return {
            color: '#EFE6FD',
            value: 'Prospect',
          }
        case 'rendez-vous_pris':
          return {
            color: '#EFE6FD',
            value: 'Rendez-vous pris',
          }
        case 'en_attente':
          return {
            color: '#EFE6FD',
            value: 'En attente',
          }
        case 'en_cours':
          return {
            color: '#EFE6FD',
            value: 'En cours',
          }
        case 'inventaire':
          return {
            color: '#EFE6FD',
            value: 'Inventaire',
          }
        case 'preparation_vente':
          return {
            color: '#EFE6FD',
            value: 'Préparation de vente',
          }
        case 'vente_en_cours':
          return {
            color: '#EFE6FD',
            value: 'Vente en cours',
          }
        case 'difficulte':
          return {
            color: '#EFE6FD',
            value: 'Difficulté',
          }
        case '2eme_relance':
          return {
            color: '#EFE6FD',
            value: 'Deuxième relance',
          }
        case 'mise_en_demeure':
          return {
            color: '#EFE6FD',
            value: 'Mise en demeure',
          }
        case 'regle':
          return {
            color: '#EFE6FD',
            value: 'Reglé',
          }
        case 'clos':
          return {
            color: '#EFE6FD',
            value: 'Clôturé',
          }
        case 'annule':
          return {
            color: '#EFE6FD',
            value: 'Annulé',
          }
        default:
          return {
            color: '#EFE6FD',
            value: '-',
          }
      }
    },
    getStructureColor(project: Project) {
      return project.structure?.color
    },
  },
})
